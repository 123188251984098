import { zodResolver } from '@hookform/resolvers/zod'
import { Delete, Edit } from '@mui/icons-material'
import { Box, Grid, IconButton } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { number, object, string, TypeOf } from 'zod'

import { useDestroyNoteMutation, useUpdateNoteMutation } from '#api/note-api'
import { Note, Roles } from '#api/types'
import { FormInput, FormSelect } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'

import { NoteContext } from '../../context'
import { buildOptions } from '../../utils'

type Props = {
  remark: Note
  typeId: number
}

const updateNoteSchema = object({
  id: number().optional(),
  category_id: number(),
  period: string().min(1).max(150),
  name: string().min(3).max(150),
  user_id: number()
})

export type UpdateNoteInput = TypeOf<typeof updateNoteSchema>

const AccordionRemark = ({ remark, typeId }: Props) => {
  const { getNotesByType } = useContextSafe(NoteContext)
  const users = useAppSelector((state) => state.userState.users) ?? []
  const types = useAppSelector((state) => state.houseState.types) ?? []

  const methods = useForm<UpdateNoteInput>({
    resolver: zodResolver(updateNoteSchema),
    defaultValues: useMemo(
      () =>
        ({
          id: remark.id,
          category_id: typeId,
          period: String(remark.period),
          name: remark.name,
          user_id: remark.user_id
        }) as any,
      [remark.id, remark.name, remark.period, remark.user_id, typeId]
    )
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  const [updateNote, { isLoading, isSuccess }] = useUpdateNoteMutation()

  const [destroyNote, { isLoading: isLoadingDestroy, isSuccess: isSuccessDestroy }] = useDestroyNoteMutation()

  useEffect(() => {
    if (isSuccessDestroy) {
      toast.success('Замечание успешно удаленно')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDestroy])

  useEffect(() => {
    if (isSuccess) {
      toast.success('Замечание успешно обновленно')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<UpdateNoteInput> = async (values) => {
    await updateNote(values as any)
    getNotesByType(null)
  }

  const onDestroyNote = async () => {
    await destroyNote(remark.id)
    getNotesByType(null)
  }

  const optionsUsers = buildOptions(
    users.filter((user) => {
      const isExpert = (user?.roles ?? []).map(({ name }) => name).includes(Roles.EXPERT)

      return isExpert
    })
  )

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormInput size="small" mb={0} name="name" />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSelect size="small" mb={0} name="category_id" type="number" options={types} />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSelect size="small" mb={0} name="user_id" type="number" options={optionsUsers} />
          </Grid>
          <Grid item xs={12} md={1}>
            <FormInput size="small" mb={0} name="period" type="number" />
          </Grid>

          <Grid item xs={12} md={2} container direction="row" justifyContent="flex-end" alignItems="center">
            <IconButton type="submit" color="primary" aria-label="edit">
              <Edit />
            </IconButton>

            <IconButton aria-label="delete" color="error" onClick={onDestroyNote}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  )
}

export { AccordionRemark }
