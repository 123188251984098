import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { authApi } from '#api/auth-api'
import { downloadApi } from '#api/download-api'
import { houseApi } from '#api/house-api'
import { noteApi } from '#api/note-api'
import { userApi } from '#api/userApi'

import houseReducer from './features/houseSlice'
import noteReducer from './features/noteSlice'
import userReducer from './features/userSlice'

const store = configureStore({
  reducer: {
    [noteApi.reducerPath]: noteApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [houseApi.reducerPath]: houseApi.reducer,
    [downloadApi.reducerPath]: downloadApi.reducer,
    userState: userReducer,
    houseState: houseReducer,
    noteState: noteReducer
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      userApi.middleware,
      houseApi.middleware,
      noteApi.middleware,
      downloadApi.middleware
    ])
})

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

const useAppDispatch = () => useDispatch<AppDispatch>()
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { store, useAppSelector, useAppDispatch, type RootState, type AppDispatch }
