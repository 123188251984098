import { CircularProgress } from '@mui/material'

import { Table } from '#components'
import { useAppSelector } from '#store'

import { ToolbarUser } from '../'
import { HeadCell } from '../../../../components/table/type'
import { getFullAddress } from '../../../../utils'

// TODO заменить на компоненты когда будет рендринг отдельной колонки
type UserByTable = {
  id: number
  email: string
  fullName: string
  position: string
  rolesUser: string
  houses: string
}

type Props = {
  selected: any[]
  setSelected: (selected: any[]) => void
  selectedByUser: (findId: number) => void
  destroyUsers: (selected: number[]) => void
}

const TableUser = ({ selected, setSelected, selectedByUser, destroyUsers }: Props) => {
  const users = useAppSelector((state) => state.userState.users)

  const dataSource: UserByTable[] = (users ?? []).map((user) => {
    const { email, profile, roles = [], id, houses = [] } = user
    const { last_name = '', father_name = '', first_name = '', position = '' } = profile ?? {}
    const rolesUser = roles.map(({ slug }) => slug).join(', ')
    const housesFromString = houses.map((house) => getFullAddress(house)).join(', ')

    return {
      id,
      email,
      fullName: `${last_name} ${first_name} ${father_name}`,
      position,
      rolesUser,
      houses: housesFromString
    }
  })

  const headCells: HeadCell[] = [
    {
      id: 'fullName',
      numeric: true,
      disablePadding: false,
      label: 'Полное имя'
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email'
    },
    {
      id: 'position',
      numeric: true,
      disablePadding: false,
      label: 'Должность'
    },
    {
      id: 'rolesUser',
      numeric: true,
      disablePadding: false,
      label: 'Роль',
      style: {
        width: '250px'
      }
    },
    {
      id: 'houses',
      numeric: true,
      disablePadding: false,
      label: 'Закрепленные дома',
      style: {
        width: '250px'
      }
    }
  ]

  return (
    <>
      {!dataSource?.length && <CircularProgress />}

      {dataSource?.length && (
        <Table
          tableName={`Пользователи (${dataSource?.length})`}
          headCells={headCells}
          defaultOrder="fullName"
          propKey="id"
          perPage={10}
          dataSource={dataSource as UserByTable[]}
          selected={selected}
          setSelected={setSelected}
          toolbarElement={
            <ToolbarUser selectedByUser={selectedByUser} selected={selected} destroyUsers={destroyUsers} />
          }
        />
      )}
    </>
  )
}

export { TableUser }
