import { Link } from 'react-router-dom'

import { House } from '#api/types'
import { useAppSelector } from '#store'
import { getFullAddress, isAdmin } from '#utils'

import { HeadCell } from '../../../components/table/type'
import { ActionsHouse } from '../components'

const useHouseDataTable = () => {
  const user = useAppSelector((state) => state.userState.user)
  const houses = useAppSelector((state) => state.houseState.houses)

  const isAdminRight = user ? isAdmin(user) : false

  const headCells: HeadCell<House>[] = [
    {
      id: 'street',
      numeric: false,
      disablePadding: false,
      label: 'Адрес',
      align: 'left',
      render: (_: string, house: House) => {
        const address = getFullAddress(house)

        return <Link to={`/apartments/${house.id}`}>{address}</Link>
      }
    },
    {
      id: 'director',
      numeric: false,
      disablePadding: false,
      align: 'left',
      label: 'Директор'
    },
    {
      id: 'developer',
      numeric: false,
      disablePadding: false,
      align: 'left',
      label: 'Застройщик'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      align: 'left',
      label: 'Дата создания',
      render: (data) => {
        const dataToLocal = new Date(data).toLocaleDateString()

        return <>{dataToLocal}</>
      }
    }
  ]

  if (isAdminRight) {
    headCells.push({
      id: 'id',
      numeric: false,
      disablePadding: false,
      align: 'left',
      label: '',
      style: {
        width: '120px'
      },
      render: (id, house) => (
        <>
          <ActionsHouse id={id} house={house} />
        </>
      )
    })
  }

  return {
    houses,
    headCells
  }
}

export { useHouseDataTable }
