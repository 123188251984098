import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { NotesByType } from '../api/types'

interface INoteState {
  notesByType: NotesByType[] | null
  type: NotesByType | null
}

const initialState: INoteState = {
  notesByType: [],
  type: null
}

export const noteSlice = createSlice({
  initialState,
  name: 'noteSlice',
  reducers: {
    setNotes: (state, action: PayloadAction<NotesByType[]>) => {
      state.notesByType = action.payload
    },
    setNote: (state, action: PayloadAction<NotesByType>) => {
      state.type = action.payload
    }
  }
})

export default noteSlice.reducer

export const { setNotes, setNote } = noteSlice.actions
