import { RemarksByApartment } from '../../../../../redux/api/types'

const getDefaultImages = (remark: RemarksByApartment, houseId: number) =>
  remark?.photos.map(({ original, id }) => ({
    id,
    original,
    thumbnail: original,
    houseId
  })) ?? []

export { getDefaultImages }
