import { Link } from 'react-router-dom'

import { Apartment, ApartmentsAcceptanceType, House } from '#api/types'
import { useAppSelector } from '#store'
import { RemarksByType } from '#types/remark'
import { buildRelevantRemark, buildRemarksByType, getColorRow } from '#utils'

import { HeadCell } from '../../../components/table/type'
import { ColorRow } from '../../../config/constants'
import { isDirector } from '../../../utils/roles'

type Props = {
  house: House | null
  types: ApartmentsAcceptanceType[] | null
  groupByApartment: RemarksByType
}

const useDataTable = (props: Props) => {
  const { types, house, groupByApartment } = props
  const user = useAppSelector((state) => state.userState.user)
  const isDirectorRole = isDirector(user)

  if (types === null || house === null) {
    return {
      headCells: [],
      dataSource: []
    }
  }

  const headCells: HeadCell<Apartment>[] = [
    {
      id: 'number',
      numeric: true,
      disablePadding: false,
      label: 'Номер квартиры',
      style: {
        whiteSpace: 'nowrap',
        wordWrap: 'break-word'
      },
      render: (id: string, apartment) => (
        <>
          <Link to={`/apartment/${house.id}/${apartment.id}`}>{id} кв</Link>
        </>
      )
    }
  ]

  if (!isDirectorRole) {
    types.forEach(({ slug, name }) => {
      headCells.push({
        id: name,
        numeric: true,
        disablePadding: false,
        label: slug,
        align: 'center',
        style: {
          whiteSpace: 'nowrap',
          wordWrap: 'break-word'
        }
      })
    })
  } else {
    headCells.push({
      id: 'lastDate',
      numeric: true,
      disablePadding: false,
      label: 'Последнее замечание'
    })
  }

  const getDataSourceByNoDirector = (item: Apartment) => {
    const apartmentWorks = groupByApartment?.[item.id] ?? []
    const remarksByType = buildRemarksByType(apartmentWorks)

    const stateApartments = types.reduce((acc, { name, id }) => {
      const remarks = remarksByType[id] ?? []
      const relevantRemark = buildRelevantRemark(remarks)
      const colorCell = relevantRemark
        ? getColorRow(relevantRemark, ColorRow.WHITE)
        : { backgroundColor: ColorRow.GREEN }

      return {
        ...acc,
        [`${name}.options.cell-style`]: colorCell,
        [name]: relevantRemark?.endTime && new Date(relevantRemark?.endTime).toLocaleDateString()
      }
    }, {})

    return {
      id: item.id,
      number: item.number,
      ...stateApartments
    }
  }

  const getDataSourceByDirector = (item: Apartment) => {
    const apartmentWorks = groupByApartment?.[item.id] ?? []
    const relevantRemark = buildRelevantRemark(apartmentWorks)
    const colorCell = relevantRemark ? getColorRow(relevantRemark, ColorRow.WHITE) : { backgroundColor: ColorRow.GREEN }

    return {
      [`lastDate.options.cell-style`]: colorCell,
      id: item.id,
      number: item.number,
      lastDate: relevantRemark?.endTime && new Date(relevantRemark?.endTime).toLocaleDateString(),
      relevantRemark
    }
  }

  const dataSource = house.apartments.map(!isDirectorRole ? getDataSourceByNoDirector : getDataSourceByDirector)

  return {
    headCells,
    dataSource
  }
}

export { useDataTable }
