import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { useDestroyUsersByIdsMutation, useUsersMutation } from '#api/userApi'
import { LayoutPage } from '#components'
import { useAppSelector } from '#store'
import { User } from '#types/user'
import { declension } from '#utils'

import { SidebarUser, TableUser } from './components'
import { UsersDeclensions } from './utils/constants'

const UsersPage = () => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<any[]>([])
  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const roles = useAppSelector((state) => state.userState.roles)
  const users = useAppSelector((state) => state.userState.users)
  const houses = useAppSelector((state) => state.houseState.houses)

  const [destroyUsersByIds] = useDestroyUsersByIdsMutation()
  const [getUsers] = useUsersMutation()

  const fetchUsers = () => {
    getUsers(null)
  }

  const selectedByUser = (findId: number) => {
    const user = (users ?? []).find(({ id }) => findId === id)

    if (user) {
      setSelectedUser(user)
      setOpen(true)
    }
  }

  const destroyUsers = async (userIds: number[]) => {
    const result = await destroyUsersByIds({ userIds })

    if ('data' in result && typeof result.data === 'number') {
      const successMessage = `${result.data} ${declension(result?.data, UsersDeclensions)} удален(ны)`
      toast.success(successMessage)
      setSelected([])
      await getUsers(null)
    }
  }

  const openNewSidebar = () => {
    setSelectedUser(null)
    setOpen(true)
  }

  return (
    <LayoutPage
      title="Пользователи"
      afterTitleElement={
        <Button sx={{ maxWidth: '300px' }} startIcon={<PersonAddIcon />} variant="contained" onClick={openNewSidebar}>
          Создать пользователя
        </Button>
      }
    >
      <>
        <TableUser
          destroyUsers={destroyUsers}
          selectedByUser={selectedByUser}
          selected={selected}
          setSelected={setSelected}
        />

        <SidebarUser
          open={open}
          setOpen={setOpen}
          selectedUser={selectedUser}
          saveData={fetchUsers}
          setSelectedUser={setSelectedUser}
          roles={roles}
          houses={houses}
        />
      </>
    </LayoutPage>
  )
}

export default UsersPage
