import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { houseApi, useApartmentsInfoByHomeMutation } from '#api/house-api'
import { LayoutPage, Table } from '#components'
import { useAppSelector } from '#store'
import { buildGroupByApartment, getFullAddress } from '#utils'

import FullScreenLoader from '../../components/FullScreenLoader'
import { useBreadcrumbs, useDataTable } from './hooks'

const ApartmentsPage = () => {
  const params = useParams()
  const types = useAppSelector((state) => state.houseState.types)
  const apartmentsInfoByHome = useAppSelector((state) => state.houseState.remarksApartmentsByHome)

  const [getApartmentsInfoByHome] = useApartmentsInfoByHomeMutation()
  const { houseId } = params ?? { houseId: 0 }

  const { data: house = null } = houseApi.endpoints.house.useQuery(Number(houseId))

  useEffect(() => {
    getApartmentsInfoByHome(Number(houseId))
  }, [])

  const groupByApartment = buildGroupByApartment(apartmentsInfoByHome)

  const { headCells, dataSource } = useDataTable({
    house,
    types,
    groupByApartment
  })

  if (!house) {
    return <FullScreenLoader />
  }

  const fullAddress = getFullAddress(house)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const breadcrumbs = useBreadcrumbs({ fullAddress })

  return (
    <LayoutPage breadcrumbs={breadcrumbs}>
      <Table
        isSelect={false}
        hasTableToolbar={true}
        tableName={fullAddress}
        propKey="id"
        defaultOrder="number"
        perPage={10}
        headCells={headCells}
        dataSource={dataSource as any[]}
      />
    </LayoutPage>
  )
}

export default ApartmentsPage
