import { Container, Stack, Typography } from '@mui/material'
import { isValidElement, type ReactElement } from 'react'

import { Breadcrumb, BreadcrumbsType } from '../Breadcrumb'

type Props = {
  title?: string | ReactElement
  children: ReactElement | string
  afterTitleElement?: ReactElement
  breadcrumbs?: BreadcrumbsType[]
}

const LayoutPage = ({ children, title, afterTitleElement = <></>, breadcrumbs }: Props) => (
  <Container maxWidth="xl">
    <Stack sx={{ mt: 3 }} spacing={2}>
      {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} />}

      {title && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          {isValidElement(title) ? title : <Typography variant="h5">{title}</Typography>}

          {afterTitleElement}
        </Stack>
      )}

      {children}
    </Stack>
  </Container>
)

export { LayoutPage }
