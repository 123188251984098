import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeadersFormDataToken } from '../helpers/prepare-headers'
import { IGenericResponse } from './types'

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string

export const downloadApi = createApi({
  reducerPath: 'downloadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/remark/`,
    prepareHeaders: prepareHeadersFormDataToken
  }),
  endpoints: (builder) => ({
    create: builder.mutation<IGenericResponse, FormData>({
      query(data) {
        return {
          url: 'newRemark',
          method: 'POST',
          body: data
        }
      }
    })
  })
})

export const { useCreateMutation } = downloadApi
