import { IUser, RemarksByApartment, Roles } from '../../../redux/api/types'
import { CheckboxMode } from './config'

type ReturnAnswer = {
  isApproval: boolean
  message: string
}

enum StatusMessage {
  NO_REMARK = 'Закрепите пользователя за замечанием',
  SUCCESS = 'У вас есть права на редактирование данного замечания',
  DANGER = 'Вы не закреплены за данным замечанием или у вас нет прав для редактирвоания'
}

const checkingExecutionRight = (
  remarkByApartment: RemarksByApartment,
  user: IUser,
  mode: CheckboxMode
): ReturnAnswer => {
  const { user_id: userId, remark: { user_id: remarkUserId = null } = {} } = remarkByApartment

  const userIdAssignedToTheRemark = userId ?? remarkUserId ?? null

  if (userIdAssignedToTheRemark === null) {
    return {
      isApproval: false,
      message: StatusMessage.NO_REMARK
    }
  }
  const isVerifyMode = mode === CheckboxMode.VERIFY

  const hasCurrentUser = userIdAssignedToTheRemark === user.id
  const hasRightEdit = [Roles.ADMIN].includes(user.role)

  if (!isVerifyMode && hasCurrentUser && user.role === Roles.EXPERT) {
    return {
      isApproval: true,
      message: StatusMessage.SUCCESS
    }
  }

  if (isVerifyMode && user.role === Roles.SPECIALIST) {
    return {
      isApproval: true,
      message: StatusMessage.SUCCESS
    }
  }

  if ((isVerifyMode || hasCurrentUser) && hasRightEdit) {
    return {
      isApproval: true,
      message: StatusMessage.SUCCESS
    }
  }

  return {
    isApproval: false,
    message: StatusMessage.DANGER
  }
}

export { checkingExecutionRight }
