import { ColorRow } from '../../../config/constants'
import { RemarksByApartment, UnionRemarksByApartment } from '../../../redux/api/types'
import { getColorRow, getEndDate } from '../../../utils'

type SidebarTableRemark = {
  relevantRemark: RemarksByApartment & { endTime: number }
  executor: number
  'options.row-style': { backgroundColor: ColorRow }
  end_time: number
  created_at: string
  remark: string | undefined
  is_mark: boolean | number
  remarks: RemarksByApartment[]
}

const useDataSidebarTable = (remarks: RemarksByApartment[]) => {
  const dataSource: SidebarTableRemark[] = remarks.map((item) => {
    const { period, remark } = item
    const colorRow = getColorRow(item)
    const completionDay = period ?? remark?.period ?? 0
    const endTime = getEndDate(item.created_at, completionDay).getTime()
    const relevantRemark: UnionRemarksByApartment = { ...item, endTime }

    return {
      ['options.row-style']: colorRow,
      id: item.id,
      remarks,
      relevantRemark,
      created_at: item?.created_at && new Date(item?.created_at).toLocaleDateString(),
      remark: item.remark?.name,
      end_time: endTime,
      executor: item.user_id,
      is_mark: item.accepted,
      date_verify: item.date_verify,
      user_verify_id: item.user_verify_id
    }
  })

  return {
    dataSource
  }
}

export { type SidebarTableRemark, useDataSidebarTable }
