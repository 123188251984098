import NotesIcon from '@mui/icons-material/Notes'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import { LayoutPage } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'

import { AccordionNotes, SidebarNotes } from './components'
import { NoteContext, NoteContextProvider } from './context'

const NotesPage = () => {
  const [open, setOpen] = useState(false)
  const { getNotesByType } = useContextSafe(NoteContext)

  useEffect(() => {
    getNotesByType(null)
  }, [])

  return (
    <LayoutPage
      title="Замечания"
      afterTitleElement={
        <Button sx={{ maxWidth: '300px' }} startIcon={<NotesIcon />} variant="contained" onClick={() => setOpen(true)}>
          Создать замечание
        </Button>
      }
    >
      <>
        <AccordionNotes />

        <SidebarNotes open={open} setOpen={setOpen} />
      </>
    </LayoutPage>
  )
}

const NotesPageWithContext = () => (
  <NoteContextProvider>
    <NotesPage />
  </NoteContextProvider>
)

export default NotesPageWithContext
