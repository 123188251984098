import AddHome from '@mui/icons-material/AddHome'
import { Button } from '@mui/material'

import { House } from '#api/types'
import { LayoutPage, Table } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'

import { SidebarHouse, UpdateHouseSidebar } from './components'
import { HouseContext, HouseContextProvider, UpdateContextProvider } from './context'
import { useHouseDataTable } from './hooks'

const HousePage = () => {
  const { setIsOpen } = useContextSafe(HouseContext)
  const { houses, headCells } = useHouseDataTable()

  return (
    <LayoutPage
      title="Дома"
      afterTitleElement={
        <Button sx={{ maxWidth: '300px' }} startIcon={<AddHome />} variant="contained" onClick={() => setIsOpen(true)}>
          Создать дом
        </Button>
      }
    >
      <>
        <Table
          isSelect={false}
          hasTableToolbar={false}
          tableName={`Дома (${houses?.length})`}
          propKey="id"
          defaultOrder="street"
          perPage={10}
          headCells={headCells}
          dataSource={houses as House[]}
        />

        <SidebarHouse />
        <UpdateHouseSidebar />
      </>
    </LayoutPage>
  )
}

const HousePageWithContext = () => (
  <UpdateContextProvider>
    <HouseContextProvider>
      <HousePage />
    </HouseContextProvider>
  </UpdateContextProvider>
)

export default HousePageWithContext
