import { zodResolver } from '@hookform/resolvers/zod'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { any, number, object, string, TypeOf } from 'zod'

import { useRegisterUserMutation } from '#api/auth-api'
import { House } from '#api/types'
import { useUpdateUserMutation } from '#api/userApi'
import { FormInput, FormSelect } from '#components'
import { Role, User } from '#types/user'

import { HouseSelect } from '../house-select'

type Props = {
  roles: Role[]
  houses: House[]
  saveData: () => void
  selectedUser: User | null
  open: boolean
  setOpen: (flag: boolean) => void
  setSelectedUser: (user: User | null) => void
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

const registerSchema = object({
  id: number().optional(),
  position: string().optional(),
  email: string().min(1, 'Email address is required').email('Email Address is invalid'),
  first_name: string().min(1, 'Имя обязательно'),
  last_name: string().min(1, 'Фамиля обязательна'),
  father_name: string(),
  role: number(),
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  password_confirmation: string().min(1, 'Please confirm your password'),
  house_ids: any().optional()
}).refine((data) => data?.id || data.password === data.password_confirmation, {
  path: ['password_confirmation'],
  message: 'Passwords do not match'
})

const updateSchema = object({
  id: number().optional(),
  position: string().optional(),
  email: string().min(1, 'Email address is required').email('Email Address is invalid'),
  first_name: string().min(1, 'Имя обязательно'),
  last_name: string().min(1, 'Фамиля обязательна'),
  father_name: string(),
  role: number(),
  password: string().optional(),
  password_confirmation: string().optional(),
  house_ids: any().optional()
}).refine((data) => data?.id || data.password === data.password_confirmation, {
  path: ['password_confirmation'],
  message: 'Passwords do not match'
})

export type RegisterInput = TypeOf<typeof registerSchema>

export type UpdateInput = TypeOf<typeof updateSchema>

const getUser = (selectedUser: User | null) => {
  if (selectedUser === null) {
    return {
      id: undefined,
      role: undefined,
      email: undefined,
      position: undefined,
      first_name: undefined,
      last_name: undefined,
      father_name: undefined
    }
  }

  return {
    id: selectedUser.id,
    role: selectedUser?.roles?.[0]?.id,
    email: selectedUser.email,
    position: selectedUser?.profile?.position ?? '',
    first_name: selectedUser?.profile?.first_name,
    last_name: selectedUser?.profile?.last_name,
    father_name: selectedUser?.profile?.father_name
  }
}

const SidebarUser = (props: Props) => {
  const { roles, selectedUser, houses, setSelectedUser, saveData, open, setOpen } = props
  const [selectHouses, setSelectHouses] = useState<number[]>([])

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const methods = useForm<RegisterInput | UpdateInput>({
    resolver: zodResolver(selectedUser?.id ? updateSchema : registerSchema),
    defaultValues: useMemo(() => getUser(selectedUser), [selectedUser, selectHouses])
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful }
  } = methods

  useEffect(() => {
    reset(getUser(props.selectedUser))

    if (selectedUser?.houses) {
      setSelectHouses(selectedUser.houses.map(({ id }) => id))
    }
  }, [props.selectedUser, reset, selectedUser])

  const [registerUser, { isLoading, isSuccess }] = useRegisterUserMutation()

  const [updateUser, { isLoading: isLoadingUser, isSuccess: isSuccessUser }] = useUpdateUserMutation()

  useEffect(() => {
    if (isSuccess) {
      toast.success('Пользователь успешно зарегистрирован')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (isSuccessUser) {
      toast.success('Пользователь успешно обновлен')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUser])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  const onSubmitHandler: SubmitHandler<RegisterInput | UpdateInput> = async (values) => {
    if (values?.id) {
      // TODO поправить типы
      // @ts-ignore
      const { data }: { data: { user: User } } = await updateUser<User>({
        ...values,
        house_ids: selectHouses
      } as UpdateInput)

      if (data?.user) {
        setSelectedUser(data.user as User)
      }
    } else {
      await registerUser({ ...values, house_ids: selectHouses } as RegisterInput)
      setSelectHouses([])
    }
    saveData()
    setOpen(false)
  }

  const DrawerList = (
    <Box sx={{ width: '600px' }}>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
          sx={{
            p: { xs: '1rem', sm: '2rem' }
          }}
        >
          <FormInput name="last_name" label="Фамилия *" />
          <FormInput name="first_name" label="Имя *" />
          <FormInput name="father_name" label="Отчество *" />
          <FormInput name="email" label="Email *" type="email" />
          <FormInput name="position" label="Должность" />
          <FormInput name="password" label={`Пароль ${selectedUser ? '' : '*'}`} type="password" />
          <FormInput
            name="password_confirmation"
            label={`Подтверждение пароля ${selectedUser ? '' : '*'}`}
            type="password"
          />
          <FormSelect name="role" label="Роль *" options={roles} />

          <HouseSelect houses={houses} selectHouses={selectHouses} setSelectHouses={setSelectHouses} />

          <LoadingButton
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
            disableElevation
            type="submit"
            loading={isLoading}
          >
            {selectedUser ? 'Обновить' : 'Зарегистрировать'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  )

  return (
    <div>
      <Drawer open={open} role="presentation" variant="persistent" anchor="right">
        <DrawerHeader>
          <IconButton onClick={toggleDrawer(false)}>
            <Close />
          </IconButton>
        </DrawerHeader>

        {DrawerList}
      </Drawer>
    </div>
  )
}

export { SidebarUser }
