import { createApi } from '@reduxjs/toolkit/query/react'

import { CreateApartmentAcceptanceEditInput } from '../../pages/apartment/components/remark-photo-sidebar/remark-photo-sidebar'
import { CreateNoteInput } from '../../pages/notes/components/sidebar-notes/sidebar-notes'
import { setNote, setNotes } from '../features/noteSlice'
import { withBaseQuery } from '../helpers/prepare-headers'
import { IGenericResponse, NotesByType } from './types'

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string

export type RemovePhoto = {
  id: number
  houseId: number
}

export type MarkTheCompletion = {
  id: number
  is_mark: boolean
}

export type AcceptRemark = {
  id: number
  user_verify_id: number
  is_accept: boolean
}

export type DestroyApartmentAcceptance = {
  id: number
  houseId: number
}

const noteApi = createApi({
  reducerPath: 'noteApi',
  baseQuery: withBaseQuery(`${BASE_URL}/api/remark/`),
  tagTypes: ['Note'],
  endpoints: (builder) => ({
    createNote: builder.mutation<IGenericResponse, CreateNoteInput>({
      query(data) {
        return {
          url: 'create',
          method: 'POST',
          body: data
        }
      }
    }),
    destroyNote: builder.mutation<boolean, number>({
      query(id) {
        return {
          url: `destroy/${id}`,
          credentials: 'include'
        }
      }
    }),
    updateNote: builder.mutation<IGenericResponse, CreateNoteInput>({
      query(data) {
        return {
          url: 'update',
          method: 'PUT',
          body: data
        }
      }
    }),
    updateApartmentAcceptance: builder.mutation<IGenericResponse, CreateApartmentAcceptanceEditInput>({
      query(data) {
        return {
          url: '/apartment/acceptance',
          credentials: 'include',
          method: 'PUT',
          body: data
        }
      }
    }),
    notesByType: builder.mutation<NotesByType[], null>({
      query() {
        return {
          url: 'all',
          credentials: 'include'
        }
      },
      transformResponse: (result: { remarks: NotesByType[] }) => result.remarks,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setNotes(data))
        } catch (error) {}
      }
    }),
    noteByTypeId: builder.mutation<NotesByType, number>({
      query(id) {
        return {
          url: `type/${id}`,
          credentials: 'include'
        }
      },
      transformResponse: (result: { type: NotesByType }) => result.type,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setNote(data))
        } catch (error) {}
      }
    }),
    removePhoto: builder.mutation<number, RemovePhoto>({
      query({ id, houseId }) {
        return {
          url: `/remove/photo/${id}`,
          credentials: 'include',
          method: 'DELETE',
          body: {
            houseId
          }
        }
      }
    }),
    destroyApartmentAcceptance: builder.mutation<number, DestroyApartmentAcceptance>({
      query({ id, houseId }) {
        return {
          url: `/destroy/acceptance/${id}`,
          credentials: 'include',
          method: 'DELETE',
          body: {
            houseId
          }
        }
      }
    }),
    markTheCompletion: builder.mutation<boolean, MarkTheCompletion>({
      query(data) {
        return {
          url: `mark/completion`,
          credentials: 'include',
          method: 'PUT',
          body: data
        }
      }
    }),
    acceptRemark: builder.mutation<boolean, AcceptRemark>({
      query(data) {
        return {
          url: `accept`,
          credentials: 'include',
          method: 'PUT',
          body: data
        }
      }
    })
  })
})

const {
  useCreateNoteMutation,
  useNotesByTypeMutation,
  useUpdateNoteMutation,
  useDestroyNoteMutation,
  useNoteByTypeIdMutation,
  useRemovePhotoMutation,
  useUpdateApartmentAcceptanceMutation,
  useMarkTheCompletionMutation,
  useAcceptRemarkMutation,
  useDestroyApartmentAcceptanceMutation
} = noteApi

export {
  useCreateNoteMutation,
  useNotesByTypeMutation,
  useUpdateNoteMutation,
  useDestroyNoteMutation,
  useNoteByTypeIdMutation,
  useRemovePhotoMutation,
  useUpdateApartmentAcceptanceMutation,
  useMarkTheCompletionMutation,
  useAcceptRemarkMutation,
  useDestroyApartmentAcceptanceMutation,
  noteApi
}
