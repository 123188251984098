import { Print } from '@mui/icons-material'
import { Button } from '@mui/material'

import { useContextSafe } from '#hooks/use-context-safe'

import { RemarkContext } from '../../contexts'
import { GridBase } from './style'

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string

const RemarkPrintBtn = () => {
  const { paramsContext } = useContextSafe(RemarkContext)

  const onPrintCardApartment = () => {
    window.open(BASE_URL + `/pdf/remark/${paramsContext.houseId}/${paramsContext.apartmentId}`, '_blank')
  }

  const onPrintCardRemarksApartment = () => {
    window.open(BASE_URL + `/pdf/appeal/${paramsContext.houseId}/${paramsContext.apartmentId}`, '_blank')
  }

  return (
    <GridBase>
      <Button sx={{ mr: 2 }} onClick={onPrintCardApartment} startIcon={<Print />} variant="contained">
        Карточка Замечаний
      </Button>
      <Button onClick={onPrintCardRemarksApartment} startIcon={<Print />} variant="contained">
        Карточка Обращения
      </Button>
    </GridBase>
  )
}

export { RemarkPrintBtn }
