import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { houseApi, useRemarksByApartmentMutation } from '#api/house-api'
import { useUsersMutation } from '#api/userApi'
import { LayoutPage, Table } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { getFullAddress } from '#utils'

import { type BreadcrumbsType } from '../../components/Breadcrumb'
import FullScreenLoader from '../../components/FullScreenLoader'
import { RemarkCardPdf, RemarkPrintBtn, RemarkRequestCardPdf, SidebarRemark, SidebarViewAllRemarks } from './components'
import { Mode } from './components/sidebar-remark/sidebar-remark'
import {
  RemarkContext,
  RemarkContextProvider,
  RemarkNewContextProvider,
  RemarksAllViewContextProvider
} from './contexts'
import { useBreadcrumbs, useCell, useDataTable } from './hooks'

const ApartmentPage = () => {
  const { remarksByApartment, setParamsContext } = useContextSafe(RemarkContext)
  const params = useParams()

  const { apartmentId, houseId } = params ?? { apartmentId: 0, houseId: 0 }
  const types = useAppSelector((state) => state.houseState.types)
  const [getUsers] = useUsersMutation()
  const [getRemarksByApartment, { isLoading }] = useRemarksByApartmentMutation()

  const { data: house = null } = houseApi.endpoints.house.useQuery(Number(houseId))
  const apartment = (house?.apartments ?? []).find(({ id }) => id === Number(apartmentId))

  useEffect(() => {
    getUsers(null)
  }, [])

  useEffect(() => {
    setParamsContext({
      apartmentId: Number(params.apartmentId),
      houseId: Number(params.houseId)
    })
  }, [params.apartmentId, params.houseId])

  useEffect(() => {
    if (!apartment?.id) {
      return
    }

    const params = {
      apartmentId: apartment.id,
      houseId: Number(houseId)
    }

    getRemarksByApartment(params)
  }, [apartment])

  const { headCells } = useCell()

  const { dataSource } = useDataTable({ types, house, remarksByApartment })

  const fullAddress = getFullAddress(house)
  const title = `${apartment?.number} квартира`

  const breadcrumbs: BreadcrumbsType[] = useBreadcrumbs({ title, fullAddress, houseId })

  if (!house || !apartment || isLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <RemarkCardPdf number={title} fullAddress={fullAddress} remarks={remarksByApartment ?? []} />

      <RemarkRequestCardPdf number={title} house={house} fullAddress={fullAddress} remarks={remarksByApartment ?? []} />

      <LayoutPage breadcrumbs={breadcrumbs}>
        <>
          <Table
            isSelect={false}
            hasTableToolbar={true}
            tableName={
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                {`${fullAddress} (${title})`} <RemarkPrintBtn />
              </Stack>
            }
            propKey="typesOfWork"
            defaultOrder="typesOfWork"
            perPage={25}
            headCells={headCells}
            dataSource={dataSource}
          />

          <SidebarRemark mode={Mode.CREATE} apartment={apartment} />

          <SidebarViewAllRemarks title={`${fullAddress} (${title})`} houseId={Number(houseId)} />
        </>
      </LayoutPage>
    </>
  )
}

const ApartmentPageWithContext = () => (
  <RemarkContextProvider>
    <RemarkNewContextProvider>
      <RemarksAllViewContextProvider>
        <ApartmentPage />
      </RemarksAllViewContextProvider>
    </RemarkNewContextProvider>
  </RemarkContextProvider>
)

export default ApartmentPageWithContext
